var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "z-index": "9999",
      "position": "fixed",
      "top": "0",
      "left": "0",
      "width": "100%",
      "height": "100%",
      "background-color": "rgba(69, 67, 67, 0.8)"
    }
  }, [_c('img', {
    staticClass: "loading-bar",
    attrs: {
      "src": require("@/assets/icons/loading.svg")
    }
  }), _c('div', {
    staticStyle: {
      "width": "100vw",
      "height": "10vw",
      "font-size": "5vw",
      "font-weight": "bold",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "margin-top": "35vh"
    }
  }, [_vm._v("Pay result checking ~~~")])]);
}];
export { render, staticRenderFns };