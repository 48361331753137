import request from '../util/request'

export function orderLaunch(orderData) {
  return request({
    url: '/payment/order/launch',
    method: 'post',
    data: orderData
  })
}

export function orderCheck(orderId) {
  return request({
    url: '/payment/order/check',
    method: 'get',
    params: orderId
  })
}
